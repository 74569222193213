<template>
  <div class="quizz">
    <img v-if="!ended" src="./assets/office.jpg" alt="empreendedores" />
    <section v-if="!ended">
      <h1>{{ questions[current].label }}</h1>
      <ul>
        <li
          v-for="(item, index) in questions[current].answers"
          :class="[questions[current].selected === index ? 'selected' : '']"
          :key="index"
          @click="questions[current].selected = index"
          class="button"
        >
          <span>{{ index + 1 }}</span>
          <p v-html="item"></p>
        </li>
      </ul>
      <div class="action">
        <button @click="changePage">Próxima Pergunta</button>
      </div>
    </section>
    <section class="endpage" v-else>
      <h1>Muito Obrigado!</h1>
      <h2>Você alcançou uma pontuação de:</h2>

      <strong>{{ calc() }} Pontos</strong>

      <h2>
        Isso significa que sua empresa está apta para aumentar a produção e
        economizar dinheiro! Clique no botão abaixo para receber as dicas em seu
        e-mail
      </h2>

      <label>
        Confirme seu endereço de e-mail:
        <div>
          <input
            v-model="email"
            placeholder="exemplo@dominio.com.br"
            type="email"
          />
        </div>
      </label>

      <button type="button" @click="submitForm">Continuar</button>
    </section>
    <div class="alert" v-if="alert">
      <div>
        <p>{{ alert }}</p>
        <button @click="alert = false">Ok</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          id: "negocio",
          label: "Qual o tipo do seu negócio?",
          answers: [
            "Varejo",
            "Manufatura",
            "Setor de Educação",
            "Setor de Saúde",
            "Outros",
          ],
          selected: null,
        },
        // {
        //   id: "tamanho",
        //   label: "Qual o tamanho da sua organização?",
        //   answers: [
        //     "Micro Empresa",
        //     "Pequeno Porte",
        //     "Médio Porte",
        //     "Grande Porte",
        //   ],
        //   selected: null,
        // },
        {
          id: "funcionarios",
          label: "Quantos funcionários possui?",
          answers: [
            "Até 9 pessoas",
            "Entre 20 e 49 pessoas",
            "Entre 50 e 99 pessoas",
            "Entre 100 e 249 pessoas",
            "Entre 250 e 500 pessoas",
            "Mais de 500 pessoas",
          ],
          selected: null,
        },
        {
          id: "eletronicos",
          label: "Quantos equipamentos eletrônicos sua empresa possui?",
          answers: [
            "Poucos <i>iluminação e alguns computadores</i>",
            "Quantidade moderada <i>iluminação, vários computadores e periféricos como impressoras, leitores de códigos de barras, impressoras térmicas, etc</i>",
            "Grande quantidade <i>além de iluminação, computadores e periféricos, também possui máquinas laboratoriais e/ou de linha de produção</i>",
            "É totalmente dependente de aparelhos eletrônicos e disponibilidade energética",
          ],
          selected: null,
        },
        {
          id: "fluxos",
          label:
            "Como sua empresa controla os fluxos de suas atividades (sejam fluxo de produtos, pessoas, produção, etc.)?",
          answers: [
            "Ainda não há nenhum controle",
            "Tenho controle de alguns processos",
            "Tenho controle da maioria dos processos",
            "Tenho controle total de todos os fluxos da organização",
          ],
          selected: null,
        },
        {
          id: "automatizacoes",
          label: "Seus processos e fluxos são automatizados?",
          answers: [
            "Totalmente manuais <i>Minha organização consegue funcionar apenas com operações manuais. É possível realizar as atividades com máquinas não automáticas</i>",
            "Poucos processos automatizados <i>Algumas atividades precisam ser desempenhadas por máquinas de maneira automática. Outros processos podem ser realizados por ação humana</i>",
            "Vários processos são automatizados <i>A maioria das atividades são executadas por máquinas de maneira autônoma, precisando de poucos operadores</i>",
            "Todos os processos são automatizados <i>Todas as atividades são executadas com máquinas</i>",
          ],
          selected: null,
        },
      ],
      current: 0,
      alert: false,
      ended: false,
      email: "",
    };
  },
  methods: {
    changePage() {
      if (this.questions[this.current].selected !== null) {
        if (this.current + 1 < this.questions.length) {
          this.current++;
        } else {
          this.ended = true;
        }
      } else {
        this.alert = "Você precisa marcar uma das respostas para continuar!";
      }
    },
    calc() {
      const cont = this.questions.reduce((total, val) => {
        return total + val.selected + 1;
      }, 0);
      return cont;
    },
    submitForm() {
      if (this.email) {
        const form = document.querySelectorAll("#quizform")[0];

        this.questions.forEach((item) => {
          const el = document.createElement("input");
          el.setAttribute("type", "hidden");
          el.setAttribute("name", item.id);
          el.setAttribute(
            "value",
            item.answers[item.selected]
              .replaceAll("<i>", "")
              .replaceAll("</i>", "")
          );
          form.appendChild(el);
        });

        const button = form.querySelectorAll("button[type=submit]")[0];
        const points = form.querySelectorAll("input[name=points]")[0];
        const email = form.querySelectorAll("input[name=email]")[0];

        points.setAttribute("value", this.calc());
        email.setAttribute("value", this.email);
        this.alert =
          "Obrigado! Em breve você receberá um e-mail com nossas dicas";
        setTimeout(() => {
          button.click();
        }, 1000);
      } else {
        this.alert = "Preencha o seu e-mail para continuar";
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

html,
body {
  height: 100%;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #717678;
}

#app {
  height: 100%;
  padding: 20px;
}

* {
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.quizz {
  margin: 20px auto;
  max-width: 800px;
  min-height: 600px;
  background-color: #fff;
  background-position: 250px 101px;
  background-repeat: no-repeat;
  padding: 30px;
  background-size: 750px;
  overflow: hidden;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.3);
}

.quizz img {
  position: absolute;
  bottom: -30px;
  right: -100px;
  height: 500px;
}

h1 {
  font-size: 32px;
  margin: 20px 0;
  color: #717678;
  font-weight: normal;
  text-align: center;
}

ul {
  list-style: none;
}

.button {
  background-color: #dd7033;
  margin: 15px 10px;
  padding: 10px 20px;
  cursor: pointer;
  transition: all 0.3s;
  max-width: 300px;
  color: #f1f1f1;
}

.button:hover {
  background-color: #e68f5d;
  z-index: 1;
}

.button.selected {
  background-color: #6693d1;
  transform: scale(1.1) rotateZ(-2deg);
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.button span {
  position: absolute;
  width: 25px;
  height: 25px;
  line-height: 25px;
  color: #fff;
  background-color: #f2a26b;
  text-align: center;
  border-radius: 50%;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
}

.button.selected span {
  background-color: #497bc2;
}

.button i {
  display: block;
  font-size: 12px;
}

.action button {
  background-color: #358334;
  color: #fff;
  padding: 5px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
}

.alert {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert > div {
  background-color: #fff;
  padding: 20px;
  text-align: center;
}

.alert button {
  background-color: #dd7033;
  color: #fff;
  border: none;
  padding: 10px 50px;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
}

.endpage {
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: #edecec;
}

.endpage h2 {
  font-size: 22px;
  color: #474747;
  font-weight: normal;
  text-align: center;
  max-width: 500px;
}

.endpage strong {
  font-size: 52px;
  color: #dd7033;
  margin: 20px;
}

.endpage button {
  background-color: #358334;
  color: #fff;
  padding: 5px;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 26px;
  cursor: pointer;
  margin-top: 20px;
}

.endpage label {
  text-align: center;
  margin-top: 20px;
}

.endpage input {
  height: 40px;
  width: 300px;
  margin-top: 20px;
  text-align: center;
  font-size: 22px;
  border: solid 1px #999;
  border-radius: 5px;
}

.endpage input::placeholder {
  color: #ccc;
}

@media (max-width: 600px) {
  h1 {
    font-size: 26px;
  }

  .quizz {
    padding: 10px;
  }

  img {
    opacity: 0.5;
  }

  .button {
    font-size: 12px;
  }

  .button i {
    font-size: 10px;
  }

  .endpage h2 {
    font-size: 16px;
  }

  .endpage input {
    width: 200px;
    font-size: 14px;
  }

  .endpage strong {
    font-size: 32px;
  }
}
</style>
